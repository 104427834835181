import * as React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertColor,
    Typography,
} from "@mui/material";
import CclInfoTable, {
    CclInfoTableFormValue,
    CclInfoTableRowTemplate,
} from "../../../../../components/common/cclInfoTable/cclInfoTable";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import {
    useGetSessionSetUpByEventKeyQuery,
    useGetValidUSPSMutation,
    usePutSessionSetUpMutation,
} from "../../../../../services/cclTokenedSessionApi";
import { CclInfoTblSetupInfoTemplate } from "./cclInfoTblSetupInfoTemplate";
import {
    SessionProgramData,
    SessionSetupData,
} from "../../../../../services/types/sessionDetailTypes";
import { AccessEventSessionDetails } from "../../../../../services/types/accessEventTypes";
import CclGenericAccordian from "../../../../../components/common/cclGenericAccordian";
import ComponentLoader from "../../../../../components/common/componentLoader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CclLoadingButton from "../../../../../components/common/cclButtons/cclLoadingButton";

interface SessionSetupAccordionProps {
    esKey: number;
    readOnly?: boolean;
    showSnackbar: (message: string, severity: AlertColor) => void;
}

const CancellationPolicyF2F: { val: string; option: string }[] = [
    { val: "0", option: "Select" },
    { val: "1", option: "Anytime" },
    { val: "2", option: "1 Month" },
    { val: "3", option: "2 Weeks" },
    { val: "4", option: "1 Week" },
    { val: "5", option: "72 Hours" },
    { val: "6", option: "48 Hours" },
    { val: "7", option: "36 Hours" },
    { val: "8", option: "24 Hours" },
    { val: "9", option: "Other" },
];

const SessionSetupAccordion: React.FC<SessionSetupAccordionProps> = (props) => {
    const {
        data: sessionSetupData,
        isSuccess,
        isLoading,
    } = useGetSessionSetUpByEventKeyQuery(props.esKey.toString(), {
        skip: !props.esKey || props.esKey <= 0,
    });
    const [isValidUSPS] = useGetValidUSPSMutation();
    const [putSessionSetup, { isLoading: sessionSetupIsLoading }] = usePutSessionSetUpMutation();
    const [completing, setCompleting] = React.useState<boolean>(false);
    const { logEvent } = useLogAccessEvent();

    const [finalTemplate, setFinalTemplate] = React.useState<CclInfoTableRowTemplate[]>([]);
    const [localSessionProgramData, setLocalSessionProgramData] =
        React.useState<SessionProgramData | null>(null);
    const [sessionValidationMsg, setSessionValidationMsg] = React.useState<string>("");
    //eslint-disable-next-line
    const [expanded, setExpanded] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (sessionSetupData == null) {
            setLocalSessionProgramData(null);
            setFinalTemplate([]);
            return;
        }

        let localSetupData: SessionSetupData = { ...sessionSetupData };
        localSetupData.sessionProgramData = { ...sessionSetupData.sessionProgramData };

        // make sure program dates are utc
        localSetupData.sessionProgramData.startDate = new Date(
            localSetupData.sessionProgramData.startDate + "Z"
        );
        localSetupData.sessionProgramData.endDate = new Date(
            localSetupData.sessionProgramData.endDate + "Z"
        );
        localSetupData.sessionProgramData.preworkInvitationDate = new Date(
            localSetupData.sessionProgramData.preworkInvitationDate + "Z"
        );
        localSetupData.sessionProgramData.surveyCompletionDate = new Date(
            localSetupData.sessionProgramData.surveyCompletionDate + "Z"
        );
        localSetupData.sessionProgramData.scoreDate = new Date(
            localSetupData.sessionProgramData.scoreDate + "Z"
        );
        localSetupData.sessionProgramData.reminder1 = new Date(
            localSetupData.sessionProgramData.reminder1 + "Z"
        );
        localSetupData.sessionProgramData.reminder2 = new Date(
            localSetupData.sessionProgramData.reminder2 + "Z"
        );
        localSetupData.sessionProgramData.reminder3 = new Date(
            localSetupData.sessionProgramData.reminder3 + "Z"
        );

        setLocalSessionProgramData(localSetupData.sessionProgramData);

        var newTemplate = CclInfoTblSetupInfoTemplate;
        /* USPS */
        if (sessionSetupData.sessionProgramData.programTypeId !== 3) {
            newTemplate = newTemplate.filter(
                (e: { id: string }) =>
                    e.id !== "cornerstoneSessionId" &&
                    e.id !== "isSelfRegistration" &&
                    e.id !== "eventType"
            );
        }
        let rowitem: CclInfoTableRowTemplate | undefined;
        /* Hotel */
        if (sessionSetupData.sessionProgramData.programTypeDerivedId === 2) {
            rowitem = newTemplate.find((r: { id: string }) => r.id === "hotelName");
            if (rowitem && rowitem.valueField && sessionSetupData.hotelListSelect != null)
                rowitem.valueField.selectOptions = sessionSetupData.hotelListSelect.map((ho) => {
                    return { val: ho.hotelID.toString(), option: ho.hotelName };
                });
        } else {
            newTemplate = newTemplate.filter((e: { id: string }) => e.id !== "hotelName");
        }
        /* CNX */
        if (
            sessionSetupData.sessionProgramData.programTypeId === 10 &&
            sessionSetupData.sessionProgramData.hostingPlatformId === 7
        ) {
            rowitem = newTemplate.find(
                (r: { id: string }) => r.id === "cnxCancellationPolicyF2FName"
            );
            if (rowitem && rowitem.valueField)
                rowitem.valueField.selectOptions = CancellationPolicyF2F;

            rowitem = newTemplate.find(
                (r: { id: string }) => r.id === "cnxCancellationPolicyPhoneName"
            );
            if (rowitem && rowitem.valueField)
                rowitem.valueField.selectOptions = CancellationPolicyF2F;
        } else {
            newTemplate = newTemplate.filter(
                (e: { id: string }) =>
                    e.id !== "cnxCancellationPolicyF2FName" &&
                    e.id !== "cnxCancellationPolicyPhoneName"
            );
        }

        // for now remove the program template selection/field
        newTemplate = newTemplate.filter((e: { id: string }) => e.id !== "name");
        if (props.readOnly === true) {
            newTemplate.forEach((e: CclInfoTableRowTemplate) => {
                e.editable = false;
            });
        }
        if (sessionSetupData.sessionProgramData.hostingPlatformId === 3) {
            newTemplate.forEach((item: CclInfoTableRowTemplate) => {
                item.editable = false;
            });
        }

        setFinalTemplate(newTemplate);
    }, [sessionSetupData, props.readOnly]);

    const validateSessionProgramData = (sessionData: SessionProgramData): boolean => {
        let msg = "";
        if (sessionData.isSelfRegistration && !sessionData.eventType) {
            msg = "Event Type is required for Self Registration Sessions";
        }
        if (sessionData.cornerstoneSessionId != null && sessionData.cornerstoneSessionId !== "") {
            let s = "" + sessionData.cornerstoneSessionId;
            var USPS = s.match(
                "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
            );
            if (USPS === null) {
                if (msg !== "") msg += ", ";
                msg += "Invalid USPS/Cornerstone Id";
            } else {
                isValidUSPS(sessionData.cornerstoneSessionId!)
                    .unwrap()
                    .then((result) => {
                        if (!result.success) {
                            let currentMsg = sessionValidationMsg;
                            if (currentMsg !== "") currentMsg += ", ";
                            currentMsg += "Invalid USPS/Cornerstone Id";
                        }
                    });
            }
        }
        if (
            sessionData.programTypeDerivedId === 2 &&
            sessionData.hotelId &&
            sessionData.hotelId === 0 &&
            sessionData.statusTypeId === 1
        ) {
            if (msg !== "") msg += ", ";
            msg += "Please select a Hotel";
        }

        const scoreDate = new Date(sessionData.scoreDate.toDateString());
        const surveyCompletionDate = new Date(sessionData.surveyCompletionDate.toDateString());
        const preworkInvitationDate = new Date(sessionData.preworkInvitationDate.toDateString());
        const reminder1Date =
            sessionData.reminder1 != null ? new Date(sessionData.reminder1.toDateString()) : null;
        const reminder2Date =
            sessionData.reminder2 != null ? new Date(sessionData.reminder2.toDateString()) : null;
        const reminder3Date =
            sessionData.reminder3 != null ? new Date(sessionData.reminder3.toDateString()) : null;
        const endDate = new Date(sessionData.endDate.toDateString());

        // check score date restrictions
        if (scoreDate == null) {
            if (msg !== "") msg += ", ";
            msg += "Score date must be set";
        } else if (scoreDate > endDate) {
            if (msg !== "") msg += ", ";
            msg += "Score date must be prior to session end date";
        }

        // check survey completion date restrictions
        if (sessionData.surveyCompletionDate == null) {
            if (msg !== "") msg += ", ";
            msg += "Survey invitation date must be set";
        } else if (surveyCompletionDate > scoreDate) {
            if (msg !== "") msg += ", ";
            msg += "Survey completion date must be prior to score date";
        } else if (surveyCompletionDate > endDate) {
            if (msg !== "") msg += ", ";
            msg += "Survey completion date must be prior to session end date";
        }

        // check prework invitation date restrictions
        if (preworkInvitationDate == null) {
            if (msg !== "") msg += ", ";
            msg += "Pre-work invitation date must be set";
        } else if (
            preworkInvitationDate > surveyCompletionDate ||
            preworkInvitationDate > scoreDate
        ) {
            if (msg !== "") msg += ", ";
            msg += "Pre-work invitation date must be prior to survey completion and score dates";
        } else if (preworkInvitationDate > endDate) {
            if (msg !== "") msg += ", ";
            msg += "Prework invitation date must be prior to session end date";
        }

        // check reminder date restrictions
        if (
            reminder1Date != null &&
            (reminder1Date < preworkInvitationDate || reminder1Date > endDate)
        ) {
            if (msg !== "") msg += ", ";
            msg +=
                "Reminder date 1 must be between the prework invitation date and session end date";
        }

        if (
            reminder2Date != null &&
            (reminder2Date < preworkInvitationDate || reminder2Date > endDate)
        ) {
            if (msg !== "") msg += ", ";
            msg +=
                "Reminder date 2 must be between the prework invitation date and session end date";
        }

        if (
            reminder3Date != null &&
            (reminder3Date < preworkInvitationDate || reminder3Date > endDate)
        ) {
            if (msg !== "") msg += ", ";
            msg +=
                "Reminder date 3 must be between the prework invitation date and session end date";
        }
        setSessionValidationMsg(msg);
        return msg === "";
    };

    const completeSetup = () => {
        if (!localSessionProgramData) {
            props.showSnackbar("Setup Completion Failed", "error");
            return;
        }

        if (localSessionProgramData?.statusTypeId === 1) {
            props.showSnackbar("Setup Completion Failed", "error");
            return;
        }

        let setupData = { ...localSessionProgramData };
        setupData.statusTypeId = 1;
        saveSetupData(setupData);
        setCompleting(true);
    };

    const saveSetupData = (setupData: SessionProgramData | null) => {
        if (!setupData) {
            props.showSnackbar("Setup Update Failed", "error");
            return;
        }

        if (!validateSessionProgramData(setupData)) {
            props.showSnackbar("Setup Update Failed", "error");
            return;
        }

        const isCompleteOption = setupData.statusTypeId === 1;
        putSessionSetup(setupData)
            .unwrap()
            .then((result) => {
                setCompleting(false);
                if (result.success) {
                    const evtData: AccessEventSessionDetails = {
                        projectCode: setupData.programCode ?? "",
                    };

                    if (isCompleteOption) {
                        logEvent("SessionSetupCompleted", evtData);
                        props.showSnackbar("Setup Completed", "success");
                    } else {
                        logEvent("SessionSetupSaved", evtData);
                        props.showSnackbar("Setup Updated", "success");
                    }
                } else {
                    if (isCompleteOption) {
                        props.showSnackbar("Setup Completion Failed", "error");
                    } else {
                        props.showSnackbar("Setup Update Failed", "error");
                    }
                }
            })
            .catch(() => {
                setCompleting(false);
                if (isCompleteOption) {
                    props.showSnackbar("Setup Completion Failed", "error");
                } else {
                    props.showSnackbar("Setup Update Failed", "error");
                }
            });
    };

    const handleUpdate = (changes: CclInfoTableFormValue[]) => {
        if (changes.length === 0) return;
        if (localSessionProgramData === null) return;
        let newLocal: SessionProgramData = { ...localSessionProgramData };
        changes.forEach((element: CclInfoTableFormValue) => {
            if (element.name === "cornerstoneSessionId") {
                newLocal[element.name] = element.value === "" ? null : element.value;
            } else if (element.name === "hotelName") {
                if (element.value.val !== "0") {
                    newLocal["hotelName"] = element.value.option;
                    newLocal["hotelId"] = parseInt(element.value.val);
                } else {
                    newLocal["hotelName"] = "";
                    newLocal["hotelId"] = 0;
                }
            } else if (
                element.name === "preworkInvitationDate" ||
                element.name === "surveyCompletionDate" ||
                element.name === "scoreDate"
            ) {
                let temp = new Date(element.value);
                let dt = new Date(
                    Date.UTC(temp.getFullYear(), temp.getMonth(), temp.getDate(), 0, 0, 0, 0)
                );
                newLocal[element.name] = dt;
            } else if (
                element.name === "reminder1" ||
                element.name === "reminder2" ||
                element.name === "reminder3"
            ) {
                if (element.value === "" || element.value === null) {
                    newLocal[element.name] = null;
                } else {
                    let temp = new Date(element.value);
                    let dt = new Date(
                        Date.UTC(temp.getFullYear(), temp.getMonth(), temp.getDate(), 0, 0, 0, 0)
                    );
                    newLocal[element.name] = dt;
                }
            } else {
                const key: keyof SessionProgramData = element.name as any; // Explicitly define the type of 'key'
                newLocal[key] = element.value as never;
            }
        });

        validateSessionProgramData(newLocal);
        setLocalSessionProgramData(newLocal);
    };

    if (isLoading) {
        return (
            <CclGenericAccordian headingText="Setup" defaultExpanded={true} sx={{ h: "100%" }}>
                <ComponentLoader msg={"Loading Session Setup Info"} />
            </CclGenericAccordian>
        );
    }

    if (isSuccess && sessionSetupData != null) {
        return (
            <Accordion
                sx={expanded ? { height: "100%" } : {}}
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ width: "100%", marginLeft: "15px", fontWeight: "bolder" }}>
                        Setup
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <CclInfoTable
                        onSave={() => saveSetupData(localSessionProgramData)}
                        template={finalTemplate}
                        data={sessionSetupData.sessionProgramData}
                        onChange={handleUpdate}
                        disableSave={sessionValidationMsg !== ""}
                        changeAlertSeverity={sessionValidationMsg !== "" ? "error" : undefined}
                        changeAlertMessage={
                            sessionValidationMsg !== "" ? sessionValidationMsg : undefined
                        }
                        saving={sessionSetupIsLoading}
                        miscButton={
                            sessionSetupData.sessionProgramData.statusTypeId !== 1 ? (
                                <CclLoadingButton
                                    onClick={completeSetup}
                                    mode={"primary"}
                                    loading={sessionSetupIsLoading && completing}
                                >
                                    Complete Setup
                                </CclLoadingButton>
                            ) : null
                        }
                    />
                </AccordionDetails>
            </Accordion>
        );
    }

    // error case
    return (
        <CclGenericAccordian headingText="Setup" defaultExpanded={true} sx={{ height: "100%" }}>
            <Alert severity="error">
                There was an error retrieving Setup information for this session
            </Alert>
        </CclGenericAccordian>
    );
};

export default SessionSetupAccordion;
