import * as React from "react";
import { Alert, AlertColor, Box, Card, Stack, TextField, Typography } from "@mui/material";
import BiographySectionAccordian from "./biographySectionAccordian";
import {
    useDownloadPDFMutation, //eslint-disable-next-line
    useGetBiographyQuery,
    useSaveBiographyTitleMutation,
} from "../../../services/cclTokenedEnterpriseCoachingBiographyApi";
import PageLoader from "../../common/pageLoader";
import { BioPDF, Biography } from "../../../services/types/bioApiTypes";
import { AccessEventIndividualDetails } from "../../../services/types/accessEventTypes";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import CclGenericAccordian from "../../common/cclGenericAccordian";
import CclAlertSnackbar from "../../common/cclAlertSnackbar";
import CclLoadingButton from "../../common/cclButtons/cclLoadingButton";

interface BiographyBoxProps {
    pKey: string;
    readonly?: boolean;
    imKey?: string;
    email: string;
}

const BiographyBox: React.FC<BiographyBoxProps> = (props) => {
    const claimservice = new UserClaimsService();
    const expandAll = true;
    const [imKey] = React.useState(props.imKey || claimservice.GetCurrentUserId());
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");

    const { data, isLoading, isError } = useGetBiographyQuery(props.pKey);
    const [saveBiographyTitle, { isLoading: isTitleLoading }] = useSaveBiographyTitleMutation();
    const [downloadPDF, { isLoading: downloadPDFIsLoading }] = useDownloadPDFMutation();
    const { logEvent } = useLogAccessEvent();
    const [title, setTitle] = React.useState(data?.title);
    const [titleEdited, setTitleEdited] = React.useState<boolean>(false);

    React.useEffect(() => {
        setTitle(data?.title);
    }, [data]);

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const savedBioEvent = (imkey: string) => {
        const tag: string = props.readonly || props.imKey ? "BioEdited" : "UserSavedBio";
        const evtData: AccessEventIndividualDetails = {
            imKey: imkey,
            email: props.email,
        };

        logEvent(tag, evtData);
    };

    const saveBiographyTitleClick = () => {
        if (data) {
            let bio: Biography = {
                id: data.id,
                title: title ? title : "",
                pKey: Number(props.pKey),
                biographySectionInstances: [],
            };
            saveBiographyTitle(bio)
                .unwrap()
                .then(() => {
                    ShowSnackbarElement("Bio Updated", "success");
                })
                .catch((error) => {
                    ShowSnackbarElement("Bio Update Failed", "error");
                });
            setTitleEdited(false);
            savedBioEvent(imKey);
        }
    };

    const handleTitleChange = (newText: string) => {
        setTitle(newText);
        setTitleEdited(newText !== data?.title);
    };

    const makeFileName = (firstName: string, lastName: string) => {
        const now = new Date();
        let monthInt = now.getMonth() + 1;
        let month = monthInt > 9 ? monthInt.toString() : "0" + monthInt.toString();
        let date = now.getDate() > 9 ? now.getDate().toString() : "0" + now.getDate().toString();
        let fileName =
            firstName + "_" + lastName + "_" + now.getFullYear() + "_" + month + "_" + date;

        fileName = fileName.replaceAll(".", "");
        return fileName;
    };

    const handleDownloadPDFClick = () => {
        const params: BioPDF = {
            pKey: Number(props.pKey),
            pdfName: makeFileName(
                data?.firstName ? data.firstName : "",
                data?.lastName ? data.lastName : ""
            ),
        };
        downloadPDF(params)
            .unwrap()
            .then(() => {
                const tag: string =
                    props.readonly || props.imKey ? "BioDownloaded" : "UserDownloadedBio";
                const evtData: AccessEventIndividualDetails = {
                    imKey: imKey,
                    email: props.email,
                };

                logEvent(tag, evtData);
                ShowSnackbarElement("Download Completed", "success");
            })
            .catch((error) => {
                ShowSnackbarElement("Download Failed", "error");
            });
    };

    const allSectionsComplete = (): boolean => {
        let result = true;
        if (!data) return false;
        if (data && data.title && data.title.length === 0) return false;

        if (!data.title) {
            return false;
        }

        if (data && data.biographySectionInstances) {
            data.biographySectionInstances.forEach((x) => {
                if (x.sectionContent === null || x.sectionContent.length === 0) result = false;
            });
        }
        return result;
    };

    if (isLoading) {
        return <PageLoader msg={""} />;
    }

    if (isError) {
        return (
            <Stack width={1}>
                <Alert severity="error">"We encountered an error acquiring this biography"</Alert>
            </Stack>
        );
    }

    return (
        <Box sx={{ marginBottom: 2 }} width={1} maxWidth={1200}>
            <Stack direction={"column"} spacing={4} width={1}>
                {showSnackbar ? (
                    <CclAlertSnackbar
                        open={true}
                        onClose={() => setShowSnackbar(false)}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                    />
                ) : null}
                <Card sx={{ display: "flex", flexDirection: "row", p: 3, alignItems: "center" }}>
                    <Typography sx={{ marginLeft: "15px", fontWeight: "bolder" }}>
                        Biography
                    </Typography>
                    <CclLoadingButton
                        loading={downloadPDFIsLoading}
                        onClick={handleDownloadPDFClick}
                        disabled={!allSectionsComplete()}
                        sx={{ ml: "auto", mr: 2 }}
                        mode={"primary"}
                    >
                        Download PDF
                    </CclLoadingButton>
                </Card>
                <CclGenericAccordian headingText="Title" sx={{ width: "100%" }}>
                    <Stack direction={"column"} spacing={4}>
                        <Box width={"100%"}>
                            <TextField
                                required
                                id={"title"}
                                fullWidth={true}
                                label="Enter Title"
                                variant="outlined"
                                value={title}
                                onChange={(e) => handleTitleChange(e.target.value)}
                            />
                        </Box>
                        <Stack direction={"row"}>
                            {titleEdited && (
                                <Alert sx={{ width: "100%", mr: 2 }} severity="info">
                                    You have pending changes. Press Save to commit them.
                                </Alert>
                            )}
                            <CclLoadingButton
                                onClick={saveBiographyTitleClick}
                                loading={isTitleLoading}
                                disabled={!titleEdited}
                                sx={{ ml: "auto", mr: 2 }}
                                mode={"primary"}
                            >
                                Save
                            </CclLoadingButton>
                        </Stack>
                    </Stack>
                </CclGenericAccordian>
                {data?.biographySectionInstances.map((s) => (
                    <BiographySectionAccordian
                        key={s.id}
                        expanded={expandAll}
                        editMode={true}
                        bioSection={s}
                        pKey={props.pKey}
                        imKey={props.imKey ? props.imKey : ""}
                    />
                ))}
            </Stack>
        </Box>
    );
};

export default BiographyBox;
