import * as React from "react";
import { Box, Grid } from "@mui/material";
import CclInfoTable, {
    CclInfoTableRowTemplate,
    CclInfoTableFormValue,
} from "../../../../components/common/cclInfoTable/cclInfoTable";
import { CclInfoTblResourceInfoTemplate } from "./cclInfoTblResourceInfoTemplate";
import {
    Profile,
    UpdateCclManagedProfilePropertiesPayload,
} from "../../../../services/types/enterpriseCoachingApiTypes";
import { flattenObject } from "../../../../utilities/flattenObject";
import ComponentLoader from "../../../../components/common/componentLoader";
import {
    useGetAllProfileReportingLocationsQuery,
    useUpdateCclManagedProfilePropertiesMutation,
    useGetDemographicsByProfileKeyQuery,
    useGetAllProfileReportingRegionsQuery,
    usePostDemographicsByProfileKeyMutation,
    useGetRolesByProfileKeyQuery,
} from "../../../../services/cclTokenedEnterpriseCoachingApi";
import { eInfoTableValueFieldEditType } from "../../../../components/common/cclInfoTable/cclInfoTableValueField";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import { CclInfoTblMyInfoAdminEditTemplate } from "../../../my-access/accessDetail/myInfoDetail/cclInfoTblMyInfoAdminEditTemplate";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import {
    SaveProfileAddressData,
    SaveProfileDemographicDataPayload,
    SaveProfileDemographicsData,
} from "../../../../services/types/myAccessTypes";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import ProfileImage from "../../../my-access/accessDetail/myInfoDetail/profileImage";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import { useGetCclUserAccountDetailQuery } from "../../../../services/cclTokenedGrandCentralApi";
import RolesDataGrid from "./rolesDataGrid";
import { useGetResourceByEmailQuery } from "../../../../services/cclTokenedResourceApi";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";

interface ResourceInfoPanelProps {
    pKey: string;
    profile: Profile;
}

const ResourceInfoPanel: React.FC<ResourceInfoPanelProps> = (props) => {
    const claimService = new UserClaimsService();
    const [picture, setPicture] = React.useState<string>("");
    const [name, setName] = React.useState<string>("");
    const [modTemplate, setModTemplate] = React.useState<CclInfoTableRowTemplate[]>([]);
    const [profileTemplate, setProfileTemplate] = React.useState<CclInfoTableRowTemplate[]>([]);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);

    const { data: ReportingLocationList } = useGetAllProfileReportingLocationsQuery();
    const [updateManagedProperties, { isError, isLoading }] =
        useUpdateCclManagedProfilePropertiesMutation();
    const { logEvent } = useLogAccessEvent();
    const { data: userData, refetch } = useGetDemographicsByProfileKeyQuery(props.pKey);
    const { data: reportingRegion } = useGetAllProfileReportingRegionsQuery();
    const { data: enterpriseData } = useGetCclUserAccountDetailQuery(props.profile.email, {
        skip: props.profile.email == null || props.profile.email === "",
    });
    const { data: rolesData, isLoading: isRoleDataLoading } = useGetRolesByProfileKeyQuery(
        props.pKey
    );
    const { data: resource } = useGetResourceByEmailQuery(props.profile.email, {
        skip: props.profile.email == null || props.profile.email === "",
    });
    const [saveInfoData, { isError: saveError, isLoading: saveLoading }] =
        usePostDemographicsByProfileKeyMutation();

    const labelStyle = "original";
    const displayValue = "UTC";
    const timezones = {
        ...allTimezones,
    };

    const { options } = useTimezoneSelect({ labelStyle, displayValue, timezones });

    React.useEffect(() => {
        var newTemplate = CclInfoTblResourceInfoTemplate;

        var rowitem = newTemplate.find((r) => r.id === "fullName");
        rowitem = newTemplate.find((r) => r.id === "imKey");
        if (rowitem && rowitem.valueField) {
            rowitem.valueField.editFieldType = eInfoTableValueFieldEditType.Link;
            rowitem.valueField.url = `/user?email=${encodeURI(props.profile.email)}&imkey=${
                props.profile.imKey
            }`;
        }

        newTemplate = CclInfoTblMyInfoAdminEditTemplate;

        if (reportingRegion != null && reportingRegion.length > 0) {
            let repLoc = [...reportingRegion];
            repLoc.sort((a, b) => a.name.localeCompare(b.name));
            rowitem = newTemplate.find((r) => r.id === "prrKey");
            if (rowitem) {
                if (rowitem.valueField) {
                    rowitem.valueField.selectOptions = repLoc.map((c) => {
                        return { val: c.prrKey.toString(), option: c.name };
                    });
                }
                rowitem.editable = claimService.IsUserCclAdminOrSuperAdmin();
            }
        }

        if (typeof ReportingLocationList !== "undefined" && ReportingLocationList.length > 0) {
            rowitem = newTemplate.find((r) => r.id === "prlKey");
            let repLoc = [...ReportingLocationList];
            repLoc.sort((a, b) => a.name.localeCompare(b.name));
            if (rowitem) {
                if (rowitem.valueField) {
                    rowitem.valueField.selectOptions = repLoc.map((l) => {
                        return { val: l.prlKey.toString(), option: l.description };
                    });
                }
                rowitem.editable = claimService.IsUserCclAdminOrSuperAdmin();
            }
        }

        // eslint-disable-next-line
    }, [ReportingLocationList, reportingRegion]);

    React.useEffect(() => {
        const enterpriseDetail = enterpriseData?.find(
            (e) => e.email.toLowerCase() === props.profile.email.toLowerCase()
        );

        var newTemplate = CclInfoTblResourceInfoTemplate;
        var rowitem = newTemplate.find((r) => r.id === "ceContactId");
        if (typeof rowitem !== "undefined" && rowitem !== null) {
            rowitem.valueField.value = enterpriseDetail?.ceContactId ?? "";
        }

        rowitem = newTemplate.find((r) => r.id === "bookableresourceid");
        if (typeof rowitem !== "undefined" && rowitem !== null && resource) {
            rowitem.valueField.value =
                resource[0].bookableresourceid ?? "This user has no bookable resource id";
        }

        setModTemplate([...newTemplate]);

        newTemplate = CclInfoTblMyInfoAdminEditTemplate;
        rowitem = newTemplate.find((r) => r.id === "fullName");
        if (typeof rowitem !== "undefined" && rowitem !== null) {
            rowitem.valueField.editFieldType = eInfoTableValueFieldEditType.Link;
            rowitem.valueField.url = `/user?email=${encodeURI(props.profile.email)}&imkey=${
                props.profile.imKey
            }`;
        }
        if (rowitem && rowitem.valueField) {
            rowitem.valueField.value = props.profile.firstName + " " + props.profile.lastName;
        }
        setProfileTemplate([...newTemplate]);

        if (!userData) {
            return;
        }

        rowitem = newTemplate.find((r) => r.id === "timeZoneId");
        if (rowitem && rowitem.valueField && userData.ianaTimeZoneId != null) {
            for (let i = 0; i < options.length; i++) {
                if (options[i].value.toLowerCase() === userData.ianaTimeZoneId.toLowerCase()) {
                    rowitem.valueField.value = options[i].label;
                    break;
                } else {
                    rowitem.valueField.value = userData.ianaTimeZoneId;
                }
            }
        }

        setPicture(userData ? userData.profilePictureUrl : "");
        setName(userData.firstName + " " + userData.lastName);
        // eslint-disable-next-line
    }, [props.profile, userData, enterpriseData, resource]);

    //Currently there is nothing that changes on the d365 table so it does not need to be hooked up. If that changes it is simple to re-enable
    const handleSave = (newGmKey: string, newPrlKey: string) => {
        if (newGmKey == null && newPrlKey == null) return;

        const payload: UpdateCclManagedProfilePropertiesPayload = {
            imKey: props.profile.imKey,
            payload: {
                pKey: +props.pKey,
                imKey: props.profile.imKey,
                prlKey: +newPrlKey ? +newPrlKey : undefined,
                prKey: undefined,
                gmKey: +newGmKey ? +newGmKey : undefined,
            },
        };

        updateManagedProperties(payload)
            .unwrap()
            .then(() => {
                setOpenSnackbar(true);
                const evtData: AccessEventIndividualDetails = {
                    imKey: props.profile.imKey.toString(),
                    email: props.profile.email,
                };

                logEvent("ResourceDetailsEdited", evtData);
            });
    };

    const handleChange = (changes: CclInfoTableFormValue[]) => {
        console.log(changes);
    };

    const handleProfileSave = (changes: CclInfoTableFormValue[]) => {
        const newRegion = changes.find((c) => c.name === "prrKey");
        const newGmKey = changes.find((c) => c.name === "gmKey");
        const newPrlKey = changes.find((c) => c.name === "prlKey");

        if (newGmKey || newPrlKey) {
            handleSave(
                newGmKey?.value.val ? newGmKey.value.val : null,
                newPrlKey?.value.val ? newPrlKey.value.val : null
            );
        }

        const address: SaveProfileAddressData = {
            spmKey: props.profile.address ? props.profile.address.spmKey : null,
            cmKey: props.profile.address ? props.profile.address.cmKey : null,
            addressOne: props.profile.address ? props.profile.address.addressOne : null,
            addressTwo: props.profile.address ? props.profile.address.addressTwo : null,
            city: props.profile.address ? props.profile.address.city : null,
            postalCode: props.profile.address ? props.profile.address.postalCode : null,
        };

        const tempData: SaveProfileDemographicDataPayload = {
            addressDTO: address,
            preferredPhoneKey: props.profile.preferredCtKey,
            officePhone: props.profile.officePhone,
            homePhone: props.profile.homePhone,
            mobilePhone: props.profile.mobilePhone,
            originCmKey: props.profile.nationalityKey,
            ianaTimeZoneId: props.profile.ianaTimeZoneId,
            prrKey: +newRegion?.value.val ?? undefined,
            prlKey: +newPrlKey?.value.val ?? undefined,
            gmKey: +newGmKey?.value.val ?? undefined,
        };

        let packagedData: SaveProfileDemographicsData = {
            pKey: props.pKey,
            payload: tempData,
        };

        saveInfoData(packagedData)
            .unwrap()
            .then(() => {
                setOpenSnackbar(true);
                const evtData: AccessEventIndividualDetails = {
                    imKey: props.profile.imKey.toString(),
                    email: props.profile.email,
                };
                logEvent("ResourceDetailsEdited", evtData);
            });
    };

    if (modTemplate.length > 0) {
        return (
            <Grid container spacing={4}>
                <Grid item xs={12} md={7} height={"100%"}>
                    <CclGenericAccordian headingText={"Profile"}>
                        <>
                            <Box style={{ display: "flex", flexDirection: "row" }}>
                                <ProfileImage
                                    name={name}
                                    picture={picture}
                                    refetch={refetch}
                                    imKey={`${props.profile.imKey}`}
                                    restrictAccess={true}
                                    fromResources={true}
                                    email={props.profile.email}
                                />
                            </Box>
                            <Box sx={{ m: 2, borderRadius: 3 }}>
                                {reportingRegion &&
                                    reportingRegion.length > 0 &&
                                    ReportingLocationList &&
                                    ReportingLocationList?.length > 0 && (
                                        <CclInfoTable
                                            template={profileTemplate}
                                            data={flattenObject(userData)}
                                            onSave={handleProfileSave}
                                            onChange={handleChange}
                                            saving={saveLoading}
                                        />
                                    )}
                            </Box>
                        </>
                    </CclGenericAccordian>
                </Grid>
                <Grid item xs={12} md={5} height={"100%"}>
                    <CclGenericAccordian headingText={"D365 Records"}>
                        {ReportingLocationList && ReportingLocationList?.length > 0 && (
                            <Box sx={{ m: 2, borderRadius: 3 }}>
                                <CclInfoTable
                                    template={modTemplate}
                                    data={flattenObject(props.profile)}
                                    onSave={handleSave}
                                    onChange={handleChange}
                                    saving={isLoading}
                                />
                            </Box>
                        )}
                    </CclGenericAccordian>
                    <CclGenericAccordian headingText={"D365 Roles"}>
                        <RolesDataGrid isLoading={isRoleDataLoading} data={rolesData} />
                    </CclGenericAccordian>
                </Grid>
                <CclAlertSnackbar
                    message={isError || saveError ? "Profile Update Failed" : "Profile Updated"}
                    severity={isError || saveError ? "error" : "success"}
                    open={openSnackbar}
                    onClose={() => {
                        setOpenSnackbar(false);
                    }}
                />
            </Grid>
        );
    }
    return <ComponentLoader msg={"Loading"} />;
};

export default ResourceInfoPanel;
