import * as React from "react";
import {
    Badge,
    Box,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    TextField,
    Typography,
} from "@mui/material";
import { CoachListItem } from "./primaryCoachAssignmentList";
import { Participant } from "../../../../../services/types/enterpriseParticipantApiTypes";
import { SessionDetailAssignedCoach } from "../../../../../services/types/sessionDetailTypes";
import { useGetAvailableCoachesBySessionKeyQuery } from "../../../../../services/cclTokenedEnterpriseCoachingApi";
import { PersonInfo } from "../../../../../models/personInfo";
import CclButton from "../../../../../components/common/cclButtons/cclButton";

interface OtherResourceAssignmentListProps {
    sessionId: string;
    allParticipants: Participant[];
    assignedCoachList: SessionDetailAssignedCoach[];
    onCoachSelect: (coach: PersonInfo | null) => void;
    unassignCoachesParticipants: (coach: PersonInfo) => void;
}

const OtherResourceAssignmentList: React.FC<OtherResourceAssignmentListProps> = (props) => {
    const [coachList, setCoachList] = React.useState<CoachListItem[]>([]); // coaches not assigned to any participants
    const [assignedCoachList, setAssignedCoachList] = React.useState<CoachListItem[]>([]); // coaches assigned to at least one participant
    const [filteredCoachList, setFilteredCoachList] = React.useState<CoachListItem[]>([]); // coaches not assigned to any participants
    const {
        data: allCoaches,
        isLoading,
        isSuccess,
    } = useGetAvailableCoachesBySessionKeyQuery(props.sessionId, { skip: props.sessionId === "" });
    const [coachFilter, setCoachFilter] = React.useState<string>("");
    const [selectedCoachImKey, setSelectedCoachImKey] = React.useState<number>(-1);

    // should only change once (empty to full set of available coaches)
    React.useEffect(() => {
        // set unassigned coach list
        if (isLoading) {
            setCoachList([]);
        } else if (isSuccess) {
            let baseCoachList: CoachListItem[] = allCoaches.coaches.map((c) => {
                return {
                    pkey: c.pKey,
                    info: { imKey: c.imKey, firstName: c.firstName, lastName: c.lastName },
                    assignments: [],
                    otherAssignments: [],
                };
            });
            // remove assigned coaches from the base list
            let assignedCoachImKeys = assignedCoachList.map((c) => c.info.imKey);
            let availableCoachList = baseCoachList.filter(
                (bc) => assignedCoachImKeys.indexOf(bc.info.imKey) === -1
            );
            setCoachList(
                availableCoachList.sort((a, b) => {
                    return (
                        a.info.lastName.localeCompare(b.info.lastName) ||
                        a.info.firstName.localeCompare(b.info.firstName)
                    );
                })
            );
        }
        // eslint-disable-next-line
    }, [allCoaches]);

    // changed coach assignment list (will change frequently)
    React.useEffect(() => {
        if (allCoaches?.coaches == null || allCoaches.coaches.length === 0) return;

        let assignedCoachImKeys = props.assignedCoachList.map((c) => c.imKey);
        let availableCoachList = allCoaches.coaches.filter(
            (c) => assignedCoachImKeys.indexOf(c.imKey) === -1
        );
        setCoachList(
            availableCoachList
                .map((c) => {
                    return {
                        pkey: c.pKey,
                        info: { imKey: c.imKey, firstName: c.firstName, lastName: c.lastName },
                        assignments: [],
                        otherAssignments: [],
                    };
                })
                .sort((a, b) => {
                    return (
                        a.info.lastName.localeCompare(b.info.lastName) ||
                        a.info.firstName.localeCompare(b.info.firstName)
                    );
                })
        );

        if (
            props.assignedCoachList &&
            props.assignedCoachList.length > 0 &&
            props.allParticipants &&
            props.allParticipants.length > 0
        ) {
            // convert coach info to coach list items
            const baseAssignedCoaches: CoachListItem[] = props.assignedCoachList.map((c) => {
                return {
                    pkey: c.pKey,
                    info: { imKey: c.imKey, firstName: c.firstName, lastName: c.lastName },
                    assignments: [],
                    otherAssignments: [],
                };
            });

            // remove duplicates
            const removedDups: CoachListItem[] = baseAssignedCoaches.reduce(
                (accumalator: CoachListItem[], current: CoachListItem) => {
                    if (!accumalator.some((item) => item.info.imKey === current.info.imKey)) {
                        accumalator.push(current);
                    }
                    return accumalator;
                },
                []
            );

            // add participant info
            const tempAssignedCoaches: CoachListItem[] = removedDups.map((c: CoachListItem) => {
                // see if this coach has any primary assignments
                const assignedPax = props.assignedCoachList.filter(
                    (assignedCoach) => assignedCoach.imKey === c.info.imKey
                );
                if (assignedPax.length > 0) {
                    const assignedPaxImKeys = assignedPax.map((ac) => {
                        return ac.paxImKey;
                    });
                    // if there are any assignments, get the participant info and store in proper object
                    c.assignments = props.allParticipants
                        .filter((p) => assignedPaxImKeys.find((i) => i === p.imKey) != null)
                        .map((ap) => {
                            return {
                                imKey: ap.imKey,
                                firstName: ap.firstName,
                                lastName: ap.lastName,
                            };
                        });
                }
                return c;
            });

            setAssignedCoachList(
                tempAssignedCoaches.sort((a, b) => {
                    return (
                        a.info.lastName.localeCompare(b.info.lastName) ||
                        a.info.firstName.localeCompare(b.info.firstName)
                    );
                })
            );
        } else if (props.assignedCoachList && props.assignedCoachList.length === 0) {
            setAssignedCoachList([]);
        }
        // eslint-disable-next-line
    }, [props.assignedCoachList, props.allParticipants]);

    // changes the filtered list of coaches
    React.useEffect(() => {
        let filteredCoaches =
            coachFilter.length < 3
                ? coachList.filter((c) => c.info.imKey === selectedCoachImKey)
                : coachList.filter(
                      (c) =>
                          c.info.firstName.toLowerCase().includes(coachFilter) ||
                          c.info.lastName.toLowerCase().includes(coachFilter) ||
                          c.info.imKey === selectedCoachImKey
                  );

        setFilteredCoachList(
            filteredCoaches.sort((a, b) => {
                return (
                    a.info.lastName.localeCompare(b.info.lastName) ||
                    a.info.firstName.localeCompare(b.info.firstName)
                );
            })
        );
        // eslint-disable-next-line
    }, [assignedCoachList, coachFilter, selectedCoachImKey]);

    const filterChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        let newfilter = evt.target.value.toLowerCase();
        setCoachFilter(newfilter);
    };

    const getPaxNameList = (paxlist: PersonInfo[]): string => {
        const fullnames = paxlist
            .sort((a, b) => {
                return (
                    a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)
                );
            })
            .map((p) => `${p.firstName} ${p.lastName}`);

        return fullnames.join(", ");
    };

    const handleCoachSelect = (coach: PersonInfo) => {
        setSelectedCoachImKey(coach.imKey);
        props.onCoachSelect(coach);
    };

    const confirmUnassign = () => {
        if (
            selectedCoachImKey !== -1 &&
            allCoaches?.coaches != null &&
            allCoaches.coaches.length > 0
        ) {
            const coach = allCoaches.coaches.find((c) => c.imKey === selectedCoachImKey);
            if (coach != null) {
                props.unassignCoachesParticipants(coach);
            }
        }
    };

    return (
        <Box sx={{ height: "100%", m: 2, p: 4 }}>
            <Grid container spacing={2} sx={{ width: 1 }}>
                <Grid item xs={12}>
                    <TextField
                        value={coachFilter}
                        label="Type resource's name"
                        onChange={filterChange}
                        sx={{ width: 1, maxHeight: "57px" }}
                        id="coach-search"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <List
                            dense={true}
                            sx={{ width: "100%" }}
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Unassigned
                                </ListSubheader>
                            }
                        >
                            {filteredCoachList.length > 0 &&
                                filteredCoachList.map((coach, i) => (
                                    <ListItem key={i}>
                                        <ListItemButton
                                            selected={selectedCoachImKey === coach.info.imKey}
                                            onClick={() => handleCoachSelect(coach.info)}
                                        >
                                            <ListItemIcon></ListItemIcon>
                                            <ListItemText
                                                primary={`${coach.info.firstName} ${coach.info.lastName}`}
                                            ></ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                        </List>
                        {filteredCoachList.length <= 0 && (
                            <Typography variant="body2" sx={{ pl: 4, pb: 4 }}>
                                Type in a resource's name above
                            </Typography>
                        )}
                        <Divider />
                        {assignedCoachList.length > 0 && (
                            <List
                                dense={true}
                                sx={{ width: "100%" }}
                                subheader={
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        Assigned
                                    </ListSubheader>
                                }
                            >
                                {assignedCoachList.map((coach: CoachListItem, i: number) => (
                                    <ListItem key={i}>
                                        <ListItemButton
                                            selected={selectedCoachImKey === coach.info.imKey}
                                            onClick={() => handleCoachSelect(coach.info)}
                                        >
                                            <ListItemIcon>
                                                <Badge
                                                    sx={{ pr: 2 }}
                                                    max={props.allParticipants.length}
                                                    badgeContent={coach.assignments.length}
                                                    color="primary"
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={`${coach.info.firstName} ${coach.info.lastName}`}
                                                secondary={getPaxNameList(coach.assignments)}
                                                secondaryTypographyProps={{ variant: "caption" }}
                                            ></ListItemText>
                                            {selectedCoachImKey === coach.info.imKey && (
                                                <CclButton
                                                    size="small"
                                                    onClick={confirmUnassign}
                                                    mode={"primary"}
                                                    sx={{
                                                        whiteSpace: "nowrap",
                                                        minWidth: "max-content",
                                                    }}
                                                >
                                                    Un-Assign
                                                </CclButton>
                                            )}
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default OtherResourceAssignmentList;
